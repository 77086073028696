import * as React from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import ClientsListRnE from "../../../sections/clients-list-rne"

const RestaurantsAndEateries = () => (
  <Layout>
    <Seo
      title="Restaurants &amp; Eateries"
      description="We specialise in F&amp;B, Leisure and Luxury spaces - crafting a complete guest experience through a multidisciplinary approach."
    />
    <section className="category_area pad_btm">
      <div className="category_outer">
        <Container className="p0" fluid>
          <Row className="no-gutters">
            <Col lg={6} xl={7} className="category_image_outer">
              <div className="category_image_inner">
                <div className="category_image">
                  <div className="cat_img">
                    <StaticImage
                      src="../../../images/projects/rne_hero.jpg"
                      alt="Restaurants / Eateries"
                      layout="fullWidth"
                    />
                  </div>
                  <div className="cat_title rne_bg">
                    <h2>
                      Restaurants <br />
                      {"//"} Eateries
                    </h2>
                  </div>
                  <div className="cat_icon">
                    <StaticImage
                      src="../../../images/projects/restaurants-eateries-xl-icon.svg"
                      alt="Restaurants / Eateries icon"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} xl={5} className="cat_content_bg">
              <div className="cat_content">
                <div className="cat_heading">
                  <h3>Immersive environments that cater to the senses</h3>
                </div>
                <div className="cat_listing">
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/restaurants-&amp;-eateries#california-pizza-kitchen"
                      className="cta-project"
                    >
                      <h4>California Pizza Kitchen</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/restaurants-&amp;-eateries#olive-beach"
                      className="cta-project"
                    >
                      <h4>Olive Beach</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/restaurants-&amp;-eateries#serafina"
                      className="cta-project"
                    >
                      <h4>Serafina</h4>
                      <p>Bangalore / Hyderabad</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/restaurants-&amp;-eateries#barbecue-by-punjab-grill"
                      className="cta-project"
                    >
                      <h4>Barbecue by Punjab Grill</h4>
                      <p>Navi Mumbai</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/restaurants-&amp;-eateries#punjab-grill"
                      className="cta-project"
                    >
                      <h4>Punjab Grill</h4>
                      <p>Chennai / Gurugram / Lucknow</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/restaurants-&amp;-eateries#tim-tai-asian-deli"
                      className="cta-project"
                    >
                      <h4>Tim Tai Asian Deli</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm purple_squr">
                    <Link
                      to="/projects/restaurants-&amp;-eateries/the-fatty-bao"
                      className="cta-project"
                    >
                      <h4>The Fatty Bao</h4>
                      <p>Bangalore / Chennai / Mumbai / Kolkata / New Delhi</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm purple_squr">
                    <Link
                      to="/projects/restaurants-&amp;-eateries/cantan"
                      className="cta-project"
                    >
                      <h4>Cantan</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm purple_squr">
                    <Link
                      to="/projects/restaurants-&amp;-eateries/maffy’s"
                      className="cta-project"
                    >
                      <h4>Maffy’s</h4>
                      <p>Mumbai</p>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
    <ClientsListRnE />
  </Layout>
)

export default RestaurantsAndEateries
