import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./clients-list.yaml"

const ClientsListRnE = () => {
  return (
    <section className="clients_area pad_btm">
      <Container className="container">
        <Row>
          <Col>
            <div className="clients_content">
              <h2>{YAMLdata.title}</h2>
              <p>{YAMLdata.content}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="client_logo_list">
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_06.png"
                alt="Cantan"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_11.png"
                alt="California Pizza Kitchen"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_12.png"
                alt="Olive Bar &amp; Kitchen"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_13.png"
                alt="Punjab Grill"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_14.png"
                alt="Serafina New York"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_16.png"
                alt="Barbecue By Punjab Grill"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_18.png"
                alt="The Fatty Bao"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_19.png"
                alt="Tim Tai"
              />
            </div>
          </div>
        </Row>
      </Container>
    </section>
  )
}
export default ClientsListRnE
